import { observable, action } from "mobx";
import organizationService from "~/services/OrganizationService";

export class OrganizationStore {
  @observable organization = {};
  @observable organizationId = 0;
  @observable organizationSelected = {};
  @observable organizationLogoUrl = "";

  @action.bound
  getOrganizations = async (filter, organizationId, addEmptyItem, skip) => {
    if (skip === undefined) skip = 0;
    const data = await organizationService.getAll(filter, addEmptyItem, skip);
    if (!data || data === "") return [];

    if (organizationId === undefined || organizationId === 0) return data;
  
    let organizationsFilter = [];
    for (let i = 0; i < data.organizations.length; i++){
      if (data.organizations[i].organizationId === organizationId) {
        organizationsFilter.push(data.organizations[i]);
        break;
      }
    }
    
    if (organizationsFilter.length === 0) {
      organizationsFilter.push(await organizationService.getById(organizationId));
    }

    data.organizations = organizationsFilter;

    return data;    
  };

  @action.bound
  setOrganizationSelected = (organizationSelected) => {
    this.organizationSelected = organizationSelected;
  }

  @action.bound
  getOrganizationSelected = () => {
    return this.organizationSelected;
  }

  @action.bound
  getOrganization = async (organizationId) => {
    const data = await organizationService.getById(organizationId);
    return data ?? {};
  };

  @action.bound
  maintainOrganization = async (formData) => {    
    return await organizationService.maintain(formData);
  };

  @action.bound
  logout = async () => {
    this.organization = {}; 
  }

  @action.bound
  setOrganizationId = (organizationId) => {
    this.organizationId = parseInt(organizationId);
  }

  @action.bound
  getOrganizationId = () => {
    return this.organizationId;
  }

  @action.bound
  setOrganizationLogoUrl = (organizationLogoUrl) => {
    this.organizationLogoUrl = organizationLogoUrl;
  }

  @action.bound
  getOrganizationLogoUrl = () => {
    return this.organizationLogoUrl;
  }

  @action.bound
  statusUpdate = async (organizationId) => {
    return await organizationService.statusUpdate(organizationId);
  }  
}

const organizationStore = new OrganizationStore();
export default organizationStore;
