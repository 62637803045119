/* eslint-disable react/no-string-refs */
/* eslint-disable no-underscore-dangle */
import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Button } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";

class AlertComponent extends Component {
  static dialog(message, dialogNo, dialogYes, reconfirm) {
    AlertComponent.__singletonRef.__dialog(message, dialogNo, dialogYes, reconfirm);
  }

  static success(message) {
    AlertComponent.__singletonRef.__success(message);
  }

  static error(message) {
    AlertComponent.__singletonRef.__error(message);
  }

  constructor(props) {
    super(props);
    AlertComponent.__singletonRef = this;
  }

  alert(message, type) {
    switch (type) {
      case "success":
        toast.success(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case "danger":
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      default:
        toast.info(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }
  }

  alertDialog(message, dialogNo, dialogYes, reconfirm) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-dialog custom-dialog-xs">
            <div style={{ textAlign: "left", fontSize: "18px" }}>
              <span>Atenção</span>
            </div>
            <br />
            <span>{message}</span>
            <br />
            <br />
            <Button
              className="btn-fill btn-xs"
              onClick={() => {
                dialogNo();
                onClose();
              }}
              color="danger"
            >
              Não
            </Button>
            <Button
              className="btn-fill btn-xs"
              onClick={() => {
                if (reconfirm !== undefined && reconfirm === true) {
                  message += " Esse processo é irreversível, Tem certeza?";
                  this.alertDialog(message, dialogNo, dialogYes, false);
                } else {
                  dialogYes();
                  onClose();
                }
              }}
              color="primary"
            >
              Sim
            </Button>
          </div>
        );
      },
    });
  }

  __dialog(message, dialogNo, dialogYes, reconfirm) {
    this.alertDialog(message, dialogNo, dialogYes, reconfirm);
  }

  __success(message) {
    this.alert(message, "success");
  }

  __error(message) {
    this.alert(message, "danger");
  }

  render() {
    return (
      <div>
        <ToastContainer />
      </div>
    );
  }
}

export default AlertComponent;
