import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { IMAGE_TYPE } from "~/configs/constants";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { autoCompleteSx, isEmptyOrNullStr } from '../../helpers/utils'
import InputColor from 'react-input-color';
import CurrencyInput from 'react-currency-input-field';

import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Row,
  Col
} from "reactstrap";

function mountEvent(name,value) {
  return {
    target:{
      name: name,
      value: value,
    }
  };
}; 

const ImageComponent = ({
  onChange,
  onClick,
  onCancel,
  onFileChange,
  onInputChangeImageContainer,
  image,
  imageContainer,
  imagesContainer,
  products,
  product,
  onInputChangeProduct,
  productIsLoading,
  imageIsLoading,
  onChangeLayout,
  layout,
  layouts,
  onKeyUpLayout,
  layoutIsLoading,
  onChangeBoxColor,
  onChangeFontColor,
  onKeyUpFont,
  onChangeFont,
  fontIsLoading,
  font,
  fonts,
  onShow,
  onShare
}) => {
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <h5 className="title">Mídia</h5>
          </CardHeader>
          <CardBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                onClick();
              }}
            >    
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <span>Nome da mídia</span>
                    <Input
                      name="name"
                      type="text"
                      placeholder="Mídia"
                      value={image?.name}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>                                                   
                <Col xs={12} md={6}>
                  <FormGroup>
                    <span>Tempo de exibição (em segundos)</span>
                    <Input
                      name="showTime"
                      type="number"
                      placeholder="Tempo de exibição"
                      value={image?.showTime}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>  
                <Col xs={12} md={12}>
                  <FormGroup>
                    <span style={{marginBottom: "0px"}}>Tipo</span><br/>
                    <ButtonGroup style={{width: "100%"}}>
                      <Button style={{width: "33.3%"}} disabled={(image?.imageId > 0)} color="info" className={classNames("btn-simple", {active: image?.type === IMAGE_TYPE.image})} outline onClick={()=>onChange(mountEvent("type", 1))} >Imagem</Button>
                      <Button style={{width: "33.3%"}} disabled={(image?.imageId > 0)} color="info" className={classNames("btn-simple", {active: image?.type === IMAGE_TYPE.video})} outline onClick={()=>onChange(mountEvent("type", 2))} >Vídeo</Button>
                      <Button style={{width: "33.3%"}} disabled={(image?.imageId > 0)} color="info" className={classNames("btn-simple", {active: image?.type === IMAGE_TYPE.folder})} outline onClick={()=>onChange(mountEvent("type", 3))} >Cartaz</Button>
                      <Button style={{width: "25%"}} disabled={(image?.imageId > 0)} color="info" className={classNames("btn-simple", {active: image?.type === IMAGE_TYPE.notice})} outline onClick={()=>onChange(mountEvent("type", 4))} >Informativo</Button>
                    </ButtonGroup>                        
                  </FormGroup>                     
                </Col>                                
              </Row>
              {image?.type === IMAGE_TYPE.notice?
                <Row>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <span>Título</span>
                      <Input
                        name="noticeTitle"
                        type="text"
                        placeholder="Título"
                        value={image?.noticeTitle}
                        onChange={(e) => onChange(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <span>Informativo</span>
                      <textarea style={{fontFamily: `${font?.name}`}} className="form-control text-area-notice" value={image?.noticeMessage} onChange={(e) => onChange(e)} id="noticeMessage" name="noticeMessage" rows={5}/>
                    </FormGroup>
                  </Col>    
                </Row>
              : (<></>)}
              {image?.type === IMAGE_TYPE.folder || image?.type === IMAGE_TYPE.notice ?
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <span>Tema (Layout)</span>                 
                      <Autocomplete
                        noOptionsText={"Nenhum"}
                        onKeyDown={(event, value) =>                         
                          onKeyUpLayout(value)
                        }                   
                        onChange={(event, value) =>                         
                          onChangeLayout(value)
                        }
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        loadingText={"Carregando"}
                        loading={layoutIsLoading}
                        style={{paddingBottom: "10px"}}                                      
                        value={layout}
                        id="layout"
                        options={layouts.map((layout) => layout)}
                        size="small"
                        renderInput={(params) => (
                          <TextField  {...params}  variant='standard'/>
                        )}
                        sx={autoCompleteSx}
                        renderOption={(props, option) => {
                          return (
                            <span  {...props}>
                              <Row>
                                <Col xs={12} md={12} style={{textAlign: "center"}}>
                                  {option.name}
                                </Col>
                                <br/>
                                <Col xs={12} md={12} style={{textAlign: "center"}}>
                                  <img src={option.url} width={"30%"} alt=""/>
                                </Col>
                              </Row>
                            </span>
                          );
                        }}                      
                      />
                    </FormGroup>
                    <Col style={{textAlign: "right"}}>
                      {!isEmptyOrNullStr(layout.url) ?
                        <a target="_blank" rel="noopener noreferrer" href={layout.url}> <span style={{marginLeft: "10px", color: "white", textDecoration: "underline"}}>Visualizar</span></a>
                      : (<></>)}
                    </Col>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <span>Cor do box</span>               
                      <InputColor               
                        initialValue={image?.boxColor}
                        onChange={onChangeBoxColor}
                        className="custom-input-color"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <span>Cor da fonte</span>               
                      <InputColor               
                        initialValue={image?.fontColor}
                        onChange={onChangeFontColor}
                        className="custom-input-color"
                      />
                    </FormGroup>
                  </Col>                     
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <span>Fontes</span>                       
                      <Autocomplete
                        noOptionsText={"Nenhum"}
                        onKeyDown={(event, value) =>                         
                          onKeyUpFont(value)
                        }                   
                        onChange={(event, value) =>                         
                          onChangeFont(value)
                        }
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        loadingText={"Carregando"}
                        loading={fontIsLoading}
                        style={{paddingBottom: "10px"}}                                      
                        value={font}
                        id="font"
                        options={fonts.map((font) => font)}
                        size="small"
                        renderInput={(params) => (
                          <TextField  {...params}  variant='standard'/>
                        )}
                        sx={autoCompleteSx}
                        renderOption={(props, option) => {
                          return (
                            <span  {...props}>
                              <Row>
                                <Col xs={12} md={12} style={{textAlign: "left"}}>
                                  <div style={{fontFamily: `${option.name}`}}>{option.name}</div>
                                </Col>                              
                              </Row>
                            </span>
                          );
                        }}                      
                      />
                    </FormGroup>                  
                  </Col>                  
                </Row> 
              : (<></>)}
              {image?.type === IMAGE_TYPE.folder?
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <span>Produto</span>
                      <Autocomplete
                        noOptionsText={"Nenhum"}
                        onInputChange={(event, value) =>                         
                          onInputChangeProduct(event, value)
                        }
                        loadingText={"Carregando"}
                        loading={productIsLoading}
                        style={{paddingBottom: "10px"}}   
                        id="product"
                        value={product}               
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={products.map((product) => product)}
                        size="small"
                        renderInput={(params) => (
                          <TextField  {...params}  variant='standard'/>
                        )}
                        sx={autoCompleteSx}
                        renderOption={(props, option) => {
                          return (
                            <span  {...props}>
                              <Row>
                                <Col xs={12} md={12} style={{textAlign: "left"}}>
                                  {option.name}
                                </Col>                              
                              </Row>
                            </span>
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <span>Valor do produto</span> 
                      <CurrencyInput
                        readOnly={true}
                        className="form-control"
                        defaultValue={product?.valueStr}
                        name="value"
                        decimalsLimit="2"                            
                    />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12}> 
                    <FormGroup>
                      <span>Imagem do produto</span>                      
                      <Autocomplete
                        noOptionsText={"Nenhum"}
                        onInputChange={(event, value) =>                         
                          onInputChangeImageContainer(event, value)
                        }
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        loadingText={"Carregando"}
                        loading={imageIsLoading}
                        style={{paddingBottom: "10px"}}                                      
                        value={imageContainer}
                        id="image"
                        options={imagesContainer.map((imageContainer) => imageContainer)}
                        size="small"
                        renderInput={(params) => (
                          <TextField  {...params}  variant='standard'/>
                        )}
                        sx={autoCompleteSx}
                        renderOption={(props, option) => {
                          return (
                            <span  {...props}>
                              <div style={{textAlign: "center", width: "100%"}}>
                                <div>
                                  {option.name}
                                </div>
                                <div>
                                  <img src={option.url} style={{width: "10%"}} alt=""/>
                                </div>
                              </div>
                            </span> 
                          );
                        }}                      
                      />
                    </FormGroup>
                    <Col style={{textAlign: "right",marginBottom: "10px"}}>
                      {!isEmptyOrNullStr(imageContainer.url) ?
                        <a target="_blank" rel="noopener noreferrer" href={imageContainer.url}> <span style={{marginLeft: "10px", color: "white", textDecoration: "underline"}}>Visualizar</span></a>
                      : (<></>)}
                    </Col>
                  </Col> 
                </Row>                
              : (<></>)}
              {image?.type !== IMAGE_TYPE.folder && image?.type !== IMAGE_TYPE.notice ?                    
              (<Row>
                <Col xs={12} md={12}>
                {image?.type === IMAGE_TYPE.image ? <span>As extensões suportadas para imagens são: .JPEG .JPG .PNG .BMP .GIF</span>
                : <span>A extensão suportada para vídeos é: .MP4 ou .GIF </span>}
                  <div style={{marginBottom: "15px"}}>
                    <input id="uploadCaptureInputMidia" type="file" onChange={onFileChange} />
                  </div>  
                </Col>
              </Row>)
              : (<></>)}
              <Row>     
                {image?.type === IMAGE_TYPE.notice || (image?.path !== undefined && image?.path !== "" && image?.path != null) ?
                <Col style={{textAlign: "left"}}>                                
                  <Button
                    className="btn-fill btn-xs"
                    color="second"
                    onClick={()=>onShow()}
                  >
                    Visualizar mídia
                  </Button>
                  {/* <Button
                    className="btn-fill btn-xs"
                    color="second"
                    onClick={()=>onShare()}
                  >
                    Compartilhar
                  </Button>                 */}
                </Col>  
                : (<></>)}  
                <Col style={{textAlign: "right"}}>
                  <Button
                    className="btn-fill btn-xs"
                    color="danger"
                    type="button"
                    onClick={()=>onCancel()}
                  >
                    Voltar
                  </Button>
                  <Button
                    className="btn-fill btn-xs"
                    color="primary"
                    type="submit"
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>                  
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

ImageComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  image: PropTypes.object.isRequired
};

export default ImageComponent;